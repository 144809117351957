import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import Styles from "./index.module.scss"
// import contact from "../../images/p-contact.png"
import contact2 from "../../images/p-contact2.png"

type FormData = {
  type:
    | "お仕事のご相談やご依頼など"
    | "取材等に関するお問合せ"
    | "その他お問合せ、ご相談"
  company: string
  name: string
  url: string
  tel: string
  mail: string
  content: string
}

export const Form: React.VFC = () => {
  const [disabled, setDisabled] = useState(true)
  const [showForm, setShowForm] = useState(true)
  const { handleSubmit, register, errors } = useForm<FormData>()

  // ページ読み込み時にURLハッシュをチェックして位置調整
  useEffect(() => {
    if (typeof window !== "undefined" && window.location.hash === "#contact") {
      setTimeout(() => {
        const contactElement = document.getElementById("contact")
        if (contactElement) {
          const headerHeight = 80 // ヘッダーの高さを設定（実際の高さに合わせて調整）
          const currentPosition = window.scrollY
          const targetTop = contactElement.getBoundingClientRect().top
          const scrollPosition = currentPosition + targetTop - headerHeight - 40 // 追加のオフセット

          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          })
        }
      }, 300) // ページ読み込み後少し遅延させて実行
    }
  }, [])

  const onSubmit = (data: FormData) => {
    sendForm(data)
  }

  const sendForm = (data: FormData) => {
    const { type, company, name, url, tel, mail, content } = data
    const formData = new FormData()
    formData.append("entry.1701308801", type)
    formData.append("entry.1151130570", company)
    formData.append("entry.1274173996", url)
    formData.append("entry.470576892", name)
    formData.append("entry.1689075277", tel)
    formData.append("entry.1927827608", mail)
    formData.append("entry.2004738114", content)

    const CORS_PROXY = "https://ohtam-cors-anywhere.herokuapp.com/"
    // const CORS_PROXY = "http://localhost:8080/"
    const GOOGLE_FORM_ACTION =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfSsnL7LKrYbW0a1RLPvoiiasqNTLWfE-T3U1kHsszyi8114w/formResponse"

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION, formData)
      .then(() => {
        setShowForm(false)
        console.log("SENT!")
      })
      .catch(error => {
        console.log(error)
      })
    // const sendData = {
    //   subject: "OH-TAM ECサイト",
    //   type,
    //   company,
    //   name,
    //   url,
    //   tel,
    //   email: mail,
    //   content,
    // }

    // if (typeof window !== `undefined`) {
    //   import("firebase/app").then(firebase => {
    //     const firebaseFunctions = firebase.default
    //       .app("contactServer")
    //       .functions("asia-northeast1")
    //     const mailer = firebaseFunctions.httpsCallable("postMail")

    //     mailer(sendData)
    //       .then(() => {
    //         setShowForm(false)
    //         console.log("SENT!")
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    //   })
    // }
  }

  return (
    <section className={Styles.contact} id="contact">
      <div className={Styles.contactAnchor} id="contactAnchor"></div>
      <h2 className={Styles.pOhtamTtl6}>
        ＼ 悩むより、TAMに聞こう ／
        <span className={Styles.pOhtamTtl6Accent}>お問い合わせ</span>
      </h2>

      <p className={Styles.pOhtamTxt4}>
        経験・実績のある会社だからこそ
        <br className={Styles.uOnlySp} />
        提供できるサービスがあります。
        <br />
        ぜひ一度お問い合わせください。
      </p>
      <div className={Styles.contactForm} id="postForm">
        {showForm && (
          <form
            id="formData"
            onSubmit={handleSubmit(onSubmit)}
            noValidate={true}
          >
            <div className={Styles.contactInputGroup}>
              <p className={Styles.contactInputNote}>
                *の付いてる項目は必須項目です。
              </p>
              <div className={Styles.contactSelect}>
                <select id="select" name="type" ref={register}>
                  <option value="お仕事のご相談やご依頼など">
                    お仕事のご相談やご依頼など
                  </option>
                  <option value="取材等に関するお問合せ">
                    取材等に関するお問合せ
                  </option>
                  <option value="その他お問合せ、ご相談">
                    その他お問合せ、ご相談
                  </option>
                </select>
                <label htmlFor="select" className={Styles.label}>
                  select
                </label>
              </div>
            </div>

            <div className={Styles.contactInputGroup}>
              <p className={Styles.formError}>
                {errors.company && "※必須項目に入力してください"}
              </p>
              <input
                className={`${errors.company && Styles.hasError}`}
                id="company"
                name="company"
                ref={register({ required: true })}
                type="text"
                maxLength={50}
                placeholder="貴社名 *"
              />
              <label htmlFor="company" className={Styles.label}>
                company
              </label>
            </div>

            <div className={Styles.contactInputGroup}>
              <p className={Styles.formError}>
                {errors.url?.type === "required" &&
                  "※必須項目に入力してください"}
              </p>
              <p className={Styles.formError}>
                {errors.url?.type === "pattern" && errors.url.message}
              </p>

              <input
                className={`${errors.url && Styles.hasError}`}
                id="url"
                name="url"
                ref={register({
                  required: true,
                  pattern: {
                    value: /(https?:\/\/\S+)/g,
                    message: "URLが正しく入力されているか確認してください。",
                  },
                })}
                type="url"
                placeholder="ホームページURL *"
              />

              <label htmlFor="url" className={Styles.label}>
                url
              </label>
            </div>

            <div className={Styles.contactInputGroup}>
              <p className={Styles.formError}>
                {errors.name && "※必須項目に入力してください"}
              </p>
              <input
                className={`${errors.name && Styles.hasError}`}
                id="name"
                name="name"
                ref={register({ required: true })}
                type="text"
                maxLength={25}
                placeholder="お名前 *"
              />
              <label htmlFor="name" className={Styles.label}>
                name
              </label>
            </div>
            <div className={Styles.contactInputGroup}>
              <input
                id="tel"
                name="tel"
                ref={register}
                type="text"
                maxLength={15}
                placeholder="電話番号"
              />
              <label htmlFor="tel" className={Styles.label}>
                tel
              </label>
            </div>
            <div className={Styles.contactInputGroup}>
              <p className={Styles.formError}>
                {errors.mail?.type === "required" &&
                  "※必須項目に入力してください"}
              </p>
              <p className={Styles.formError}>
                {errors.mail?.type === "pattern" && errors.mail.message}
              </p>
              <input
                className={`${errors.mail && Styles.hasError}`}
                id="mail"
                name="mail"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message:
                      "※メールアドレスが正しく入力されているか確認してください。",
                  },
                })}
                type="email"
                maxLength={40}
                placeholder="メールアドレス *"
              />
              <label htmlFor="mail" className={Styles.label}>
                mail
              </label>
            </div>
            <div className={Styles.contactInputGroup}>
              <p className={Styles.formError}>
                {errors.content && "※必須項目に入力してください"}
              </p>
              <label htmlFor="content" className={Styles.label}>
                content
              </label>
              <textarea
                className={`${errors.content && Styles.hasError}`}
                id="content"
                name="content"
                ref={register({ required: true })}
                cols={30}
                rows={6}
                maxLength={2000}
                placeholder="お問い合わせ内容"
              />
            </div>
            <div className={Styles.contactPrivacyCheck}>
              <label className={Styles.myCheckbox}>
                <input
                  type="checkbox"
                  className={Styles.contactPrivacyInput}
                  onChange={e => {
                    setDisabled(!disabled)
                  }}
                />
                <span className={Styles.checkmark}></span>
              </label>
              <label className={Styles.contactPrivacyText}>
                当社における個人情報の取り扱いについて、
                <span>
                  <a
                    href="https://www.tam-tam.co.jp/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </span>
                に同意しました。
              </label>
            </div>
            <div className={Styles.contactSubmitWrap}>
              <input
                className={Styles.contactSubmit}
                type="submit"
                value="送信する"
                disabled={disabled}
              />
            </div>
          </form>
        )}
        {!showForm && (
          <div className={Styles.formsubmitted}>
            <div className={Styles.formsubmittedGroup}>
              <p className={Styles.formsubmittedTitle}>
                お問い合わせいただき
                <br className={Styles.displayNone} />
                ありがとうございます。
              </p>
              <p className={Styles.formsubmittedText}>
                改めて担当者よりご連絡を差し上げます。
              </p>
              <a className={Styles.contactSubmit} href="/">
                <span>TOPへ戻る</span>
              </a>
            </div>
          </div>
        )}
      </div>
      <figure className={Styles.contactImg}>
        <img src={contact2} alt="TAMくんのイラスト" />
      </figure>
    </section>
  )
}
